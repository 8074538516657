export interface QRData {
	svg: string;
	code_qr: string;
	code_enter: string;
}

export class QRDataEntity implements QRData {
	svg: string = "";
	code_enter: string = "";
	code_qr: string = "";
	save_device: boolean = false;

	constructor() {}

	setData(key: string, value: string) {
		this[key] = value;
	}
}
