import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
	components: {},
	mixins: [],
})
export default class FactorEmail extends Vue {
	@Prop({ required: true }) private loading!: boolean;

	public get isLoading() {
		return this.loading;
	}

	@Emit("two-factor")
	public handleSubmitTwoFactor(provider: string) {
		return provider;
	}

	@Emit("cancel")
	public handleAction(action: "cancel") {
		return action;
	}
}
