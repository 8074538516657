var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-stepper-content',{attrs:{"step":_vm.step}},[_c('v-card',{attrs:{"elevation":"0","min-height":"250px"}},[_c('v-card-text',{staticClass:"d-flex flex-grow-1"},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column",attrs:{"cols":"12","sm":"12","md":"6","lg":_vm.getColLg}},[_c('span',{staticClass:"text-salto text-label text-line-height"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")]),(_vm.subtitle)?_c('span',{staticClass:"text-salto text-label text-line-height mt-2"},[_vm._v(" "+_vm._s(_vm.$t(_vm.subtitle))+" ")]):_vm._e()]),(_vm.step === 1)?_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"12","sm":"12","md":"6","lg":"3"}},[_c('v-img',{attrs:{"width":"190px","src":require("@/assets/authenticator/google.png")}})],1):_vm._e(),(_vm.step === 2)?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6","lg":"3"}},[_c('v-card',{staticClass:"ma-0",attrs:{"elevation":"0","width":"100%"}},[_c('div',{staticClass:"d-flex justify-center align-center",domProps:{"innerHTML":_vm._s(_vm.qrData.svg)}}),_c('CardTextField',{attrs:{"hint":_vm.$t(
									'AuthenticationPassword.by_google.modals.text.text_step_two.text_two'
								),"reference":"code","placeholder":_vm.$t(
									'AuthenticationPassword.by_google.modals.text.text_step_two.text_two'
								),"label":"","icon_add":false,"error":false,"counter":false,"required":false,"persistent_hint":false},model:{value:(_vm.qrData.code_qr),callback:function ($$v) {_vm.$set(_vm.qrData, "code_qr", $$v)},expression:"qrData.code_qr"}})],1)],1):_vm._e(),(_vm.step === 3)?_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('v-card',{staticClass:"ma-0",attrs:{"elevation":"0"}},[_c('v-img',{attrs:{"width":"126px","height":"190px","src":require("@/assets/authenticator/celu.png")}})],1)],1):_vm._e(),(_vm.step === 3)?_c('v-col',{staticClass:"d-flex flex-column align-center justify-content-center",attrs:{"cols":"12","sm":"12","md":"3","lg":"3"}},[_c('v-card',{staticClass:"ma-0",attrs:{"elevation":"0","width":"100%"}},[_c('CardTextField',{attrs:{"hint":_vm.$t(
									'AuthenticationPassword.by_google.modals.text.text_step_three.text_three'
								),"reference":"code","placeholder":_vm.$t(
									'AuthenticationPassword.by_google.modals.text.text_step_three.text_three'
								),"label":_vm.$t(
									'AuthenticationPassword.by_google.modals.text.text_step_three.text_two'
								),"icon_add":false,"error":false,"counter":false,"required":false,"persistent_placeholder":true},model:{value:(_vm.qrData.code_enter),callback:function ($$v) {_vm.$set(_vm.qrData, "code_enter", $$v)},expression:"qrData.code_enter"}})],1)],1):_vm._e(),(_vm.step === 4)?_c('v-col',{staticClass:"d-flex flex-column align-center",attrs:{"cols":"12","sm":"12","md":"3","lg":"6"}},[_c('v-img',{attrs:{"width":"400px","src":require("@/assets/authenticator/doble_factor.png")}})],1):_vm._e()],1)],1),_c('v-divider',{staticClass:"my-1"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disabled},on:{"click":function($event){return _vm.handleAction('continue')}}},[_vm._v(" "+_vm._s(_vm.step === 4 ? _vm.$t("AuthenticationPassword.aceptar") : _vm.$t("continue"))+" ")]),(_vm.step !== 4)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.handleAction('cancel')}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }