import { DataPassword } from "@/interfaces/account/changePassword";
import { isEmpty } from "lodash";

export class DataPasswordEntity implements DataPassword {
	code_gmail: boolean = false;
	code_google: boolean = false;
	confirm_new_password: string = "";
	disabled: boolean = false;
	factor: string = "";
	new_password: string = "";
	open: boolean = false;

	constructor() {}

	isEnabledSaveButton() {
		return (
			!isEmpty(this.new_password) && !isEmpty(this.confirm_new_password)
		);
	}

	getPayload(id: number) {
		return {
			id,
			password: this.new_password,
			password_confirmation: this.confirm_new_password,
		};
	}
}
