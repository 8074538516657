
import Vue from "vue";
import Alertize from "@/components/Alertize.vue";
import {
	isRequired,
	isMinPassword,
	isMaxLength,
	isPasswordFormat,
	isValidPassword,
	isMatchingPasswords,
} from "@/services/rule-services";
// @ts-ignore
import CardMesseger from "@/components/Content/CardMesseger.vue";
import {
	Notification,
	MessageTypes,
	// @ts-ignore
} from "@/interfaces/proccess";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import CardTextField from "@/components/Content/CardTextField.vue";
import { mapActions } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
import notificationService from "@/services/notification-service";
import store from "@/store";
// @ts-ignore
import Separator from "@/components/Content/Separator.vue";
// @ts-ignore
import CardSwitch from "@/components/Content/CardSwitch.vue";
// @ts-ignore
import Dialog from "@/components/Content/Dialogs/Default.vue";
// @ts-ignore
import CardActions from "@/components/Content/CardActions.vue";
// @ts-ignore
import ModalsConfig from "@/views/Admin/Account/Password/modals/modalsConfig.vue";
import { DataPasswordEntity } from "@/models/Account/Password";

const FACTOR_EMAIL = "email";
const FACTOR_GOOGLE = "google";
const CHANGE_FACTOR = "change";

export default Vue.extend({
	name: "ChangePassword",
	props: {},
	components: {
		Alertize,
		CardMesseger,
		CardTextField,
		Separator,
		CardSwitch,
		Dialog,
		CardActions,
		ModalsConfig,
	},

	data: () => ({
		valid: false,
		loading: false,
		showPass: false,
		enabledSaveBtn: false,
		dataQR: {
			imagenSVG: "",
			codeQR: "",
		},
		dataPassword: new DataPasswordEntity(),
		error: {
			type: MessageTypes.ERROR,
			title: i18n.t("title-failed"),
			message: i18n.t("error"),
			btn_text: i18n.t(MessageTypes.CONTINUE),
			show: true,
		} as Notification,
	}),

	created() {},

	async mounted() {
		this.$nextTick(async () => {
			await this.loadDataAuthenticator();
		});
	},

	computed: {
		getID() {
			return this.getProfile?.id;
		},
		getEmail() {
			return this.getProfile?.email;
		},
		getRules() {
			return {
				isRequired,
				isMinPassword,
				isMaxLength,
				isPasswordFormat,
				isValidPassword,
				isMatchingPasswords,
			};
		},
		getProfile() {
			return this.$store.state.profile.profile;
		},
		getTextAuthtenticationGoogle() {
			return this.dataPassword.code_google
				? `${i18n.t(
						"AuthenticationPassword.by_google.fields.google_text"
				  )} ${this.dataQR.codeQR}`
				: `${i18n.t(
						"AuthenticationPassword.by_google.fields.google_text"
				  )}`;
		},
		isLoading() {
			return this.$store.state.proccess.loading;
		},
		hasCodeFactorByEmail() {
			return this.getProfile.email_two_factor_enabled;
		},
		hasCodeFactorByGoogle() {
			return this.getProfile.google_two_factor_enabled;
		},
	},

	methods: {
		...mapActions("loading", ["setLoadingData"]),
		...mapActions("auth", ["getCodeQRGoogle"]),
		...mapActions("proccess", ["setNotification"]),

		redirectTo() {
			this.setNotification({
				false: true,
				title: "",
				message: "",
				type: "",
			} as Notification);
			this.$router.push({ name: "CampaignsIndex" });
		},

		notifyError(notification: Notification) {
			return notificationService.notifyError(store, notification);
		},

		clearFields() {
			this.dataPassword.new_password = "";
			this.dataPassword.confirm_new_password = "";
		},

		closeModals(param: boolean = false) {
			this.dataPassword.open = param;
			this.dataPassword.disabled = false;
			this.loadDataAuthenticator();
		},

		changeFactor(factor: string) {
			this.dataPassword.factor = factor;
		},

		async loadDataAuthenticator() {
			this.dataPassword.code_gmail =
				this.getProfile.email_two_factor_enabled;
			this.dataPassword.code_google =
				this.getProfile.google_two_factor_enabled;
		},

		async validate() {
			let form = this.$refs.form;
			return await form.validate();
		},

		async changePassword(updateData: any) {
			return this.$store
				.dispatch("auth/changePassword", updateData)
				.catch((error: any) => {
					if (error.message == "Unauthorized") {
						this.error.message =
							"The current password does not correspond to the account";
						this.notifyError(this.error);
					}
				});
		},

		async handleSubmit() {
			try {
				if (!(await this.validate())) return;
				await this.setLoadingData(TypeLoading.loading);
				const payload = this.dataPassword.getPayload(this.getProfile.id);
				await this.changePassword(payload);
				this.clearFields();
				await this.setLoadingData();
			} catch (error) {
				await this.setLoadingData();
			}
		},

		async handleAction(action: { type: any }) {
			switch (action.type) {
				case "save":
					break;
				case "cancel":
					this.closeModals();
					break;
			}
		},

		async handleConfigGoogle() {
			if (this.dataPassword.code_google) {
				this.dataPassword.factor = this.hasCodeFactorByEmail
					? CHANGE_FACTOR
					: FACTOR_GOOGLE;
				this.dataPassword.open = true;
			} else {
				if (this.hasCodeFactorByGoogle) {
					this.dataPassword.open = true;
					this.dataPassword.factor = FACTOR_GOOGLE;
					this.dataPassword.disabled = true;
				} else {
					this.dataPassword.open = false;
				}
			}
		},

		async handleConfigEmail() {
			if (this.dataPassword.code_gmail) {
				this.dataPassword.factor = this.hasCodeFactorByGoogle
					? CHANGE_FACTOR
					: FACTOR_EMAIL;
				this.dataPassword.open = true;
			} else {
				if (this.hasCodeFactorByEmail) {
					this.dataPassword.open = true;
					this.dataPassword.factor = FACTOR_EMAIL;
					this.dataPassword.disabled = true;
				} else {
					this.dataPassword.open = false;
				}
			}
		},
	},
	watch: {
		async getProfile(val, old) {
			await this.loadDataAuthenticator();
			this.dataPassword.disabled = false;
		},
	},
});
