import Vue from "vue";
import { TwoFactor, TwoFactorCode } from "@/interfaces/user";
import { mapActions } from "vuex";
import { Notification, MessageTypes } from "@/interfaces/proccess";
import i18n from "@/plugins/i18n";
import CardTextField from "@/components/Content/CardTextField.vue";
import FactorGoogle from "@/views/Admin/Account/Componentes/FactorGoogle/index.vue";
import ChangeFactor from "@/views/Admin/Account/Componentes/ChangeFactor/index.vue";
import FactorEmail from "@/views/Admin/Account/Componentes/FactorEmail/index.vue";
import DisabledFactor from "@/views/Admin/Account/Componentes/DisabledFactor/index.vue";
import { QRDataEntity } from "@/interfaces/account/factor";

const FACTOR_EMAIL = "email";
const FACTOR_GOOGLE = "google";
const CHANGE_FACTOR = "change";
// const FACTOR_NONE = "none";

export default Vue.extend({
	name: "modalsConfigGoogle",
	props: {
		factor: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		CardTextField,
		FactorGoogle,
		ChangeFactor,
		FactorEmail,
		DisabledFactor,
	},
	data: () => ({
		e1: 1,
		loading: false,
		dataQR: new QRDataEntity(),
		activeFactorGoogle: false,
	}),
	created() {},
	mounted() {},
	destroyed() {
		this.dataQR = new QRDataEntity();
	},
	computed: {
		/**
		 * isDisabledFactor
		 * @returns
		 */
		isDisabledFactor() {
			return this.disabled;
		},

		/**
		 * isFactorGoogle
		 * @returns
		 */
		isFactorGoogle() {
			return this.factor == FACTOR_GOOGLE && !this.isDisabledFactor;
		},

		/**
		 * isFactorEmail
		 * @returns
		 */
		isFactorEmail() {
			return this.factor == FACTOR_EMAIL && !this.isDisabledFactor;
		},

		/**
		 * isChangeFactor
		 * @returns
		 */
		isChangeFactor() {
			return this.factor == CHANGE_FACTOR && !this.isDisabledFactor;
		},

		// isActive() {
		// 	return this.active;
		// },

		getProfile() {
			return this.$store.state.profile.profile;
		},

		/**
		 * getID
		 * @returns
		 */
		getID() {
			return this.getProfile?.id;
		},

		/**
		 * getEmail
		 * @returns
		 */
		getEmail() {
			return this.getProfile?.email;
		},

		/**
		 * getFactor
		 * @returns
		 */
		getFactor() {
			return this.factor;
		},

		/**
		 * hasCodeFactorByEmail
		 * @returns
		 */
		hasCodeFactorByEmail() {
			return this.getProfile.email_two_factor_enabled;
		},

		/**
		 * hasCodeFactorByGoogle
		 * @returns
		 */
		hasCodeFactorByGoogle() {
			return this.getProfile.google_two_factor_enabled;
		},
	},
	methods: {
		...mapActions("auth", [
			"getCodeQRGoogle",
			"setTwoFactorAuthenticator",
			"TwoFactorAuthenticator",
		]),

		/**
		 * changeStep
		 * @param step
		 */
		changeStep(step: number) {
			this.e1 = step;
		},

		/**
		 * setNotification
		 * @param notification
		 * @returns
		 */
		setNotification(notification: Notification) {
			return this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},

		/**
		 * getMsg
		 * @param title
		 * @param message
		 * @param messageType
		 * @returns
		 */
		getMsg(title: string, message: string, messageType: string) {
			return {
				title: title,
				message: message,
				type: messageType,
				show: true,
				details: "",
				to: undefined,
				btn_text: "close",
			};
		},

		/**
		 * changeToStep
		 * @param step
		 */
		async changeToStep(step: number) {
			switch (step) {
				case 3:
					await this.changeToStepThree();
					break;
				case 4:
					await this.changeStepThree();
					break;
			}
		},

		/**
		 * changeToStepThree
		 */
		async changeToStepThree() {
			try {
				const data = {
					user_id: this.getID,
					two_factor: {
						email_two_factor_enabled: false,
						google_two_factor_enabled: true,
						save_device: false
					},
				} as TwoFactor;
				this.loading = true;
				await this.setTwoFactorAuthenticator(data).then(async () => {
					this.e1 = 3;
					this.activeFactorGoogle = true;
				});
				this.loading = false;
			} catch (error) {}
		},

		/**
		 * changeStepThree
		 */
		async changeStepThree() {
			try {
				await this.setLoading(true);

				const data = {
					email: this.getEmail,
					two_factor_code: this.dataQR.code_enter,
				} as TwoFactorCode;

				await this.TwoFactorAuthenticator(data)
					.then(async () => {
						await this.setLoading();
						this.e1 = 4;
					})
					.catch(async () => {
						await this.setLoading();
					});
			} catch (error) {
				await this.setLoading();
			}
		},

		/**
		 * setLoading
		 * @param load
		 */
		async setLoading(load: Boolean = false) {
			this.loading = load;
		},

		/**
		 * handleCancel
		 */
		async handleCancel() {
			if (this.activeFactorGoogle && this.getFactor == FACTOR_GOOGLE) {
				await this.handleSubmitDisbaledTwoFactor(FACTOR_GOOGLE, false);
			} else {
				const action = { type: "cancel" };
				this.$emit("action", action);
			}
			this.dataQR = new QRDataEntity();
			this.e1 = 1;
		},

		/**
		 * handleGeneratedCodeQr
		 * @param event
		 */
		async handleGeneratedCodeQr(event?: any) {
			try {
				await this.setLoading(true);
				await this.getCodeQRGoogle().then((data) => {
					this.dataQR.svg = data.qr_svg;
					this.dataQR.code_qr = data.google_two_factor_code;
					this.changeStep(2);
				});
				await this.setLoading();
			} catch (error) {
				this.dataQR = new QRDataEntity();
				await this.setLoading();
			}
		},

		/**
		 * handleSubmitTwoFactor
		 * @param params
		 */
		async handleSubmitTwoFactor(params: String) {
			try {
				const data = {
					user_id: this.getID,
					two_factor: {
						email_two_factor_enabled: params == FACTOR_EMAIL,
						google_two_factor_enabled: params == FACTOR_GOOGLE,
					},
				} as TwoFactor;
				this.loading = true;
				await this.setTwoFactorAuthenticator(data).then(async () => {
					await this.fetchProfile();
					this.$emit("close-modal");
					this.setNotification(
						this.getMsg(
							MessageTypes.TITLE_SUCCESS,
							i18n.t("AuthenticationPassword.success"),
							MessageTypes.SUCCESS
						)
					);
				});
				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		},

		/**
		 * handleSubmitChangeFactor
		 * @returns
		 */
		async handleSubmitChangeFactor() {
			if (this.getProfile.email_two_factor_enabled) {
				this.$emit("change-factor", FACTOR_GOOGLE);
				return;
			}
			if (this.getProfile.google_two_factor_enabled) {
				this.$emit("change-factor", FACTOR_EMAIL);
				return;
			}
		},

		/**
		 * handleSubmitDisbaledTwoFactor
		 * @param params
		 * @param showNotify
		 */
		async handleSubmitDisbaledTwoFactor(
			params: String,
			showNotify: Boolean = true
		) {
			try {
				const data = {
					user_id: this.getID,
					two_factor: {
						email_two_factor_enabled:
							params == FACTOR_EMAIL
								? false
								: this.hasCodeFactorByEmail,
						google_two_factor_enabled:
							params == FACTOR_GOOGLE
								? false
								: this.hasCodeFactorByGoogle,
					},
				} as TwoFactor;
				this.loading = true;
				await this.setTwoFactorAuthenticator(data).then(async () => {
					await this.fetchProfile();
					this.$emit("close-modal");
					if (showNotify) {
						this.setNotification(
							this.getMsg(
								MessageTypes.TITLE_SUCCESS,
								i18n.t("AuthenticationPassword.disabled"),
								MessageTypes.SUCCESS
							)
						);
					}
				});
				this.loading = false;
			} catch (error) {
				this.loading = false;
			}
		},

		/**
		 * fetchProfile
		 * @returns
		 */
		async fetchProfile() {
			return this.$store.dispatch("profile/fetchProfile");
		},
	},
});
