import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
	components: {},
	mixins: [],
})
export default class DisabledFactor extends Vue {
	@Prop({ required: true }) private factor!: string;

	public get getFactor() {
		return this.factor;
	}

	@Emit("disabled-factor")
	public handleDisabledTwoFactor() {
		return this.getFactor;
	}

	@Emit("cancel")
	public handleAction(action: "cancel") {
		return action;
	}
}
