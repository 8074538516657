import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import CardTextField from "@/components/Content/CardTextField.vue";
import { QRDataEntity } from "@/interfaces/account/factor";
import StepperContent from "@/views/Admin/Account/Componentes/StepperContent/index.vue";

@Component({
	components: { CardTextField, StepperContent },
	mixins: [],
})
export default class FactorGoogle extends Vue {
	@Prop(Number) readonly value!: number;
	@Prop({ required: true }) public qr_data!: QRDataEntity;

	private loading: boolean = false;
	private stepper_model: number = this.value;

	public get isLoading() {
		return this.loading;
	}

	public get stepperModel() {
		return this.stepper_model;
	}

	public get qrData() {
		return this.qr_data;
	}

	@Emit("cancel")
	public handleCancel() {
		return {};
	}

	public handleAction(payload: {
		step: number;
		action: "continue" | "cancel";
	}) {
		if (payload.action === "cancel") {
			return this.handleCancel();
		}

		switch (payload.step) {
			case 1:
				this.generatedCodeQr();
				break;
			case 2:
				this.changeStep(3);
				break;
			case 3:
				this.changeStep(4);
				break;
			case 4:
				this.handleSubmitTwoFactor("google");
				break;
		}
		return payload;
	}

	@Emit("two-factor")
	public handleSubmitTwoFactor(provider: string) {
		return provider;
	}

	@Emit("to-step")
	public changeStep(step: number) {
		return step;
	}

	@Emit("generate")
	public generatedCodeQr() {
		return {};
	}

	@Emit("input")
	public onInput(value: number) {
		return value;
	}

	@Watch("value", { immediate: true })
	onValueChanged(value: number) {
		this.stepper_model = value;
	}
}
