import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component({
	components: {},
	mixins: [],
})
export default class ChangeFactor extends Vue {
	@Emit("change-factor")
	public handleChangeFactor() {
		return {};
	}

	@Emit("cancel")
	public handleAction(action: "cancel") {
		return action;
	}
}
