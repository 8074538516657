import { QRDataEntity } from "@/interfaces/account/factor";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CardTextField from "@/components/Content/CardTextField.vue";
import CardSwitch from "@/components/Content/CardSwitch.vue";

@Component({
	components: { CardTextField, CardSwitch },
	mixins: [],
})
export default class StepperContent extends Vue {
	@Prop(Number) readonly step!: number;
	@Prop({ required: true }) readonly disabled!: boolean;
	@Prop({ required: true }) readonly title!: string;
	@Prop({ required: false }) readonly subtitle!: string;
	@Prop({ required: false }) readonly qr_data!: QRDataEntity;

	public get qrData() {
		return this.qr_data;
	}

	public get getColLg() {
		if ([3, 4].includes(this.step)) return 6;
		return 9;
	}

	@Emit("action")
	public handleAction(action: "continue" | "cancel") {
		return { step: this.step, action };
	}
}
